@font-face {
  font-family: 'hotelios';
  src: url('../font/hotelios.eot?47455153');
  src: url('../font/hotelios.eot?47455153#iefix') format('embedded-opentype'),
       url('../font/hotelios.woff2?47455153') format('woff2'),
       url('../font/hotelios.woff?47455153') format('woff'),
       url('../font/hotelios.ttf?47455153') format('truetype'),
       url('../font/hotelios.svg?47455153#hotelios') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'hotelios';
    src: url('../font/hotelios.svg?47455153#hotelios') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "hotelios";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-user-tie:before { content: '\e800'; } /* '' */
.icon-id-card-alt:before { content: '\e801'; } /* '' */
.icon-bell:before { content: '\e802'; } /* '' */
.icon-power-off:before { content: '\e803'; } /* '' */
.icon-bars:before { content: '\e804'; } /* '' */
.icon-download:before { content: '\e805'; } /* '' */
.icon-file-invoice:before { content: '\e806'; } /* '' */
.icon-file-alt:before { content: '\e807'; } /* '' */
.icon-key:before { content: '\e808'; } /* '' */
.icon-pen:before { content: '\e809'; } /* '' */
.icon-trash:before { content: '\e80a'; } /* '' */
.icon-calendar-alt:before { content: '\e80b'; } /* '' */
.icon-users:before { content: '\e80c'; } /* '' */
.icon-cog:before { content: '\e80d'; } /* '' */
.icon-hotel:before { content: '\e80e'; } /* '' */
.icon-sync-alt:before { content: '\e80f'; } /* '' */
.icon-plus:before { content: '\e810'; } /* '' */
.icon-edit:before { content: '\e811'; } /* '' */
.icon-sticky-note:before { content: '\e812'; } /* '' */
.icon-user:before { content: '\e813'; } /* '' */
.icon-address-card:before { content: '\e814'; } /* '' */
.icon-chevron-down:before { content: '\e815'; } /* '' */
.icon-chevron-up:before { content: '\e816'; } /* '' */
.icon-check:before { content: '\e817'; } /* '' */
.icon-usd-circle:before { content: '\e818'; } /* '' */
.icon-times:before { content: '\e819'; } /* '' */
.icon-inventory:before { content: '\e81a'; } /* '' */
.icon-info:before { content: '\e81b'; } /* '' */
.icon-bed:before { content: '\e81c'; } /* '' */
.icon-industry:before { content: '\e81d'; } /* '' */
.icon-list-alt:before { content: '\e81e'; } /* '' */
.icon-money-check:before { content: '\e81f'; } /* '' */
.icon-wallet:before { content: '\e820'; } /* '' */
.icon-calendar-times:before { content: '\e821'; } /* '' */
.icon-calendar-week:before { content: '\e822'; } /* '' */
.icon-calendar-day:before { content: '\e823'; } /* '' */
.icon-money-bill:before { content: '\e824'; } /* '' */
.icon-wallet-full:before { content: '\e826'; } /* '' */
.icon-percent:before { content: '\e827'; } /* '' */
.icon-wallet-half:before { content: '\e828'; } /* '' */
.icon-sign-out-alt:before { content: '\e82b'; } /* '' */
.icon-clipboard:before { content: '\e82c'; } /* '' */
.icon-envelope-circle-check-regular:before { content: '\e82d'; } /* '' */
.icon-print:before { content: '\e82e'; } /* '' */
.icon-layer-group:before { content: '\e87b'; } /* '' */
.icon-layer-minus:before { content: '\e87c'; } /* '' */
.icon-layer-plus:before { content: '\e87d'; } /* '' */
.icon-copy:before { content: '\e893'; } /* '' */
