
.daterangepicker-selected { 
  color: #fff;
  font-weight: bold; 
  border: 1px solid #C6A530;
  background-color: #C6A530;
}

.daterangepicker-selected:not([disabled]) { 
  color: #fff;
  font-weight: bold; 
  border: 1px solid #C6A530;
  background-color: #C6A530;
}

.daterangepicker-selected:hover:not([disabled]) { 
  color: #fff;
  background-color: #C6A530;
  border: 1px solid #C6A530;
}

.daterangepicker-today { 
  font-weight: bold;
  font-size: 140%; 
  color: red;
}

.rdp-day_range_middle {
  opacity: 0.75 !important;
}